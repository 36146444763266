import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact, {BugsnagErrorBoundary} from "@bugsnag/plugin-react";

Bugsnag.start({
  apiKey: '01bcc94f3e5870367641c68c8eba7ae3',
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary: BugsnagErrorBoundary | undefined = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

export const wrapRootElement = ({element}: { element: any }) => {
  return ErrorBoundary ? (
    <ErrorBoundary>{element}</ErrorBoundary>
  ) : <>{element}</>;
}