exports.components = {
  "component---src-pages-1-index-tsx": () => import("./../../../src/pages/1/index.tsx" /* webpackChunkName: "component---src-pages-1-index-tsx" */),
  "component---src-pages-3-index-tsx": () => import("./../../../src/pages/3/index.tsx" /* webpackChunkName: "component---src-pages-3-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-legal-contact-tsx": () => import("./../../../src/pages/legal/contact.tsx" /* webpackChunkName: "component---src-pages-legal-contact-tsx" */),
  "component---src-pages-legal-endorsement-tsx": () => import("./../../../src/pages/legal/endorsement.tsx" /* webpackChunkName: "component---src-pages-legal-endorsement-tsx" */),
  "component---src-pages-legal-privacy-tsx": () => import("./../../../src/pages/legal/privacy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-tsx" */),
  "component---src-pages-legal-terms-tsx": () => import("./../../../src/pages/legal/terms.tsx" /* webpackChunkName: "component---src-pages-legal-terms-tsx" */),
  "component---src-pages-legal-uninstall-tsx": () => import("./../../../src/pages/legal/uninstall.tsx" /* webpackChunkName: "component---src-pages-legal-uninstall-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-brand-tsx": () => import("./../../../src/templates/brand.tsx" /* webpackChunkName: "component---src-templates-brand-tsx" */),
  "component---src-templates-brands-tsx": () => import("./../../../src/templates/brands.tsx" /* webpackChunkName: "component---src-templates-brands-tsx" */),
  "component---src-templates-categories-tsx": () => import("./../../../src/templates/categories.tsx" /* webpackChunkName: "component---src-templates-categories-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-lander-2-tsx": () => import("./../../../src/templates/lander-2.tsx" /* webpackChunkName: "component---src-templates-lander-2-tsx" */),
  "component---src-templates-lander-tsx": () => import("./../../../src/templates/lander.tsx" /* webpackChunkName: "component---src-templates-lander-tsx" */)
}

